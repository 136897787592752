import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaShieldAlt, FaChartLine, FaHandHoldingUsd } from "react-icons/fa";

const benefits = [
  { 
    icon: <FaShieldAlt className="text-blue-400 text-6xl mb-4" />, 
    title: "Secure & Trusted", 
    description: "Your investments are safe with top-rated funds." 
  },
  { 
    icon: <FaChartLine className="text-green-400 text-6xl mb-4" />, 
    title: "High Returns", 
    description: "Maximize your wealth with expert-curated funds." 
  },
  { 
    icon: <FaHandHoldingUsd className="text-yellow-400 text-6xl mb-4" />, 
    title: "Flexible Investments", 
    description: "Start small & grow at your own pace." 
  }
];

export default function HeroSection() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % benefits.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section
      style={{
        background: "linear-gradient(to bottom, #0F172A, #1E293B, #0F172A)",
      }}
      className="relative w-full min-h-screen text-white flex items-center justify-center px-6 overflow-hidden"
    >
      {/* Glowing Background Effect */}
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[70vw] h-[90vh] bg-blue-600 opacity-20 blur-[200px]"></div>

      {/* Content */}
      <div className="max-w-6xl text-center relative z-10">
        <motion.h1
          className="text-5xl md:text-6xl font-extrabold leading-tight"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Smart Investing, Secure Future 🚀
        </motion.h1>
        <motion.p
          className="mt-6 text-xl md:text-2xl text-gray-300"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Discover top-performing mutual funds on{" "}
          <span className="bg-gradient-to-r from-blue-400 to-blue-300 bg-clip-text text-transparent font-semibold">
            KuberPe
          </span>.  
          Optimize returns, diversify wisely, and secure your financial future today.
        </motion.p>

        {/* Animated Benefits Section */}
        <div className="mt-12 w-full flex items-center justify-center h-[200px]">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -40 }}
              transition={{ duration: 0.6 }}
              className="flex flex-col items-center text-gray-300 w-[50%] text-center"
            >
              {benefits[currentIndex].icon}
              <h3 className="text-2xl font-bold">{benefits[currentIndex].title}</h3>
              <p className="text-lg text-gray-400">{benefits[currentIndex].description}</p>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* CTA Button */}
        <motion.div
          className="mt-12"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <a
            href="https://kuberpe.in"
            target="_blank"
            rel="noopener noreferrer"
            className="relative px-10 py-4 bg-blue-600 hover:bg-blue-700 text-white text-lg font-semibold rounded-xl shadow-lg transition-transform transform hover:scale-105"
          >
            <span className="relative z-10">Start Investing Now</span>
            <span className="absolute inset-0 bg-blue-500 opacity-50 blur-xl rounded-xl"></span>
          </a>
        </motion.div>
      </div>
    </section>
  );
}
