import { FaFlag } from "react-icons/fa";
import { FaBolt } from "react-icons/fa6";
import { MdFileDownload } from "react-icons/md";
import { FaArrowTrendUp } from "react-icons/fa6";
import Faq from "../components/Faq";
import Carosel from "../components/Carosel";
import Graph from "../components/Graph";
import Sliderhome from "../components/Sliderhome";
import Marquee from "react-fast-marquee";
import mobileImg from "../assets/images/mobile Mockup.png"
import Navbar from "../components/Navbar";
import LazyImage from "../components/LazyImage";
import HeroSection from "../components/HeroSection";


const Home = () => {


  

  return (
    <div className="w-[100%] h-max-content font-poppinsRegular">
      <Navbar backgroundColour={'bg-richblack-900'}/>

      {/* 2nd section power your */}
      <HeroSection />
      <div className=" flex items-center bg-richblack-900  text-white justify-center w-[100%] py-10">
        {/* First Column (Paragraph Section) */}
        <div className="w-[85%] h-[100%] items-center  justify-between flex gap-10 flex-col-reverse  md:flex-row">
          <div className="md:w-[52%] xs:w-[100%] xs:p-[1rem] md:p-[0px] lg:text-6xl xl:text-7xl">
            <p className="text-black-700  leading-12 text-4xl">
              Power Your
              <br /> <b> financial freedom</b>
              <br /> with <b className="text-sky-400/75"> high returns</b>
            </p>

            <div className="w-[100%] flex  items-center  gap-[1rem] justify-between xs:flex-row py-8 md:mt-10 ">
              <div className="md:w-[50%] xs:w-[45%] h-[70%]  border-r-2  border-grey-500">
                <div className="text-2xl text-left tracking-tight">
                  <FaFlag />
                </div>
                <p className="mt-[0.6rem] md:text-2xl xs:text-sm text-left tracking-tight">
                  Start your <b>Financial Freedom</b> journey
                </p>
              </div>

              <div className="md:w-[45%] h-[70%]">
                <div className="text-2xl text-left tracking-tight">
                  <FaBolt />
                </div>
                <p className=" mt-[0.6rem] md:text-2xl xs:text-sm text-left tracking-tight ">
                  <b>Get 8-12% </b>returns with KuberPe
                </p>
              </div>
            </div>
          </div>

          {/* Second Column (Image Section) */}
          <div className="md:w-[22%] w-[90%] items-center flex md:h-[20%] justify-center m-auto">
            <LazyImage src={"./images/cartoonsnew.png"} alt={"Cartoon"}/>
          </div>
        </div>
      </div>

      {/* 3rd section Earn up to */}
      <div className=" bg-richblack-900 text-white">
        {/* Paragraph Section */}
        <div className="w-[85%] h-max-content m-auto flex items-center flex-col md:flex-row justify-between gap-10">
          <div className=" md:w-[50%] w-auto">
            <div className="md:text-3xl lg:text-5xl text-2xl  mt-8 text-left text-white md:h-28 flex items-center">
              <h2 className="font-poppinsBold">
                Earn up to <b className=" text-blue-500"> 12% Returns</b> On
                your Investment
              </h2>
            </div>
            <div className=" text-left h-10 mt-[1rem] mb-[1rem] flex items-center">
              <p className="text-white">Grow Your Wealth with India's Top Mutual Funds & Digital Gold Provider</p>
            </div>
            <form className="bg-custom-color1 text-white rounded-xl">
              <div className=" object-cover rounded-lg p-[2rem] bg-purple-200">
                <div className="text-left">
                  <p className="md:text-xl lg:text-3xl text-sm    font-poppinsBold">
                  Take the First Step in Your Investment Journey
                  </p>
                </div>

                <div className="container w-[100%] py-[1.5rem] flex flex-col lg:flex-row justify-left  gap-5 ">
                  <div className="lg:w-[50%] w-[100%]">
                    <label
                      className="block text-gray-700 md:text-xl text-sm font-poppinsBold py-2 "
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      className="shadow text-sm appearance-none rounded w-[100%] h-12 p-[0.5rem] text-black leading-tight focus:outline-none focus:shadow-outline"
                      id="name"
                      type="text"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="lg:w-[50%] ">
                    <label
                      className="block text-gray-700 md:text-xl text-sm font-poppinsBold py-2"
                      htmlFor="mobile"
                    >
                      Mobile Number
                    </label>
                    <input
                      className="shadow text-sm appearance-none rounded w-[100%] h-12 p-[0.5rem] text-black leading-tight focus:outline-none focus:shadow-outline"
                      id="mobile"
                      type="number"
                      placeholder="Enter your mobile number"
                    />
                  </div>
                </div>

                <div className=" mx-auto flex flex-col md:flex-row w-[100%] md:justify-center lg:items-center">
                  <button
                    onClick={()=>{window.open('https://play.google.com/store/apps/details?id=com.kuberpe.app', '_blank');}}
                    className="bg-blue-500 lg:w-[50%] w-[100%] text-center h-12 hover:bg-blue-700 text-white font-poppinsBold rounded focus:outline-none focus:shadow-outline"
                    type="button"
                  >
                    Lets get you invested
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/* Image Section */}
          <div className="md:w-[50%] lg:w-[40%] w-[100%] h-[100%] subpixel-antialiased">
            <LazyImage src={'./images/mobile-th2.png'} alt={"Mobile Mockup"} />
          </div>
        </div>

        <div className="  w-[100%] md:h-[350px]  h-[750px]  mt-[2rem]">
          <div className=" md:h-72  md:w-[85%] w-[100%]  m-auto flex gap-8 flex-col justify-around  justify-items-center md:flex-row items-center">
            {/* First Div */}
            <div className=" text-white bg-custom-color1 md:w-[30%]  rounded-2xl md:h-44  w-64 h-52 md:p-[2rem] lg:p-[3rem] p-[3rem]  text-center">
              <p className="font-poppinsBold text-blue-500 md:text-3xl text-2xl md:h-12"> A Growing Community</p>
              <p className="text-white  text-xl">Trusted by a rising number of <span> Investors</span> looking for stability and growth</p>
            </div>
            {/* Second Div */}
            <div className="text-white bg-custom-color1  md:w-[30%]  w-64 h-52 md:p-[2rem] lg:p-[3rem] p-[3rem] rounded-2xl md:h-44 text-center">
              <p className="font-poppinsBold text-blue-500  md:text-3xl text-2xl md:h-12">{" "}Delivering Results</p>
              <p className="text-white text-xl"> Focused on <span>consistent returns</span> and building wealth for our investors.</p>
            </div>
            {/* Third Div */}
            <div className="text-white bg-custom-color1 md:w-[30%] w-64 h-52 md:p-[2rem] lg:p-[3rem] p-[3rem] rounded-2xl md:h-44  text-center">
              <p className="font-poppinsBold text-blue-500 md:text-3xl text-2xl md:h-12">Commitment to Success</p>
              <p className=" text-white text-xl">Maintaining a 100% track record<span>on promise return</span></p>
            </div>
          </div>
        </div>
      </div>

      {/* 4th section KUBERPE  */}

      <div className=" bg-custom-color2 md:h-[550px] h-[550px]   text-white ">
        {/* Parent div */}
        <div className="w-[85%] h-[100%]  m-auto ">
          {/* Paragraph Section */}

          <div className=" md:h-60 h-32 w-[100%]  m-auto flex flex-col items-center text-center  justify-center">
            <h1 className="font-poppinsBold md:text-5xl p-[1rem] text-2xl">
              {" "}
              KuberPe is your ideal companion in this journey. Here's why:
            </h1>
          </div>

          {/* Image Section */}
          <div className="  md:w-[100%]  h-[350px] md:h-[200px] items-center flex gap-5  justify-center  flex-col md:flex-row  ">
            {/* First Div */}

            <div className="md:w-[38%] w-[100%] p-[1rem] h-[80%] border border-gray-500   flex  justify-center gap-[1rem] items-center rounded-3xl">
              <div className=" text-white w-[20%]  md:text-5xl text-3xl    rounded-full  ">
                {/* Your icon */}
                <MdFileDownload />
              </div>
              <div className="lg:w-[50%] w-[70%]">
                <p className="md:text-sm font-poppinsRegular md:h-8 text-sm">
                  Fixed deposit are great,
                </p>
                <p className="md:text-lg lg:text-xl text-sm md:mt-[0.5rem] lg:mt-[0rem]">
                  {" "}
                  <b className=" text-orange-400 font-poppinsRegular">
                    But have low returns
                  </b>
                </p>
              </div>
            </div>
            {/* Second Div */}
            <div className="   md:w-[38%] w-[100%] p-[1rem] h-[80%] border border-gray-500  flex  justify-center gap-[1rem] items-center rounded-3xl">
              <div className="  text-white w-[20%]  md:text-5xl text-3xl  rounded-full ">
                {/* Your icon */}
                <FaArrowTrendUp />
              </div>
              <div className="lg:w-[60%] w-[70%]">
                <p className="md:text-sm text-sm font-poppinsRegular md:h-8">
                  Stocks are great too,
                </p>
                <p className="md:text-lg lg:text-xl text-sm md:mt-[0.5rem] lg:mt-[0rem] font-poppinsRegular">
                  {" "}
                  <b>but have</b>{" "}
                  <b className=" text-orange-400 font-poppinsRegular">
                    high volatility
                  </b>
                </p>
              </div>
            </div>

            {/* THIRD Div */}
            <div className="   md:w-[38%] w-[100%] p-[1rem] h-[80%] border border-gray-500  flex  justify-center gap-[1rem]  items-center rounded-3xl">
              <div className="  text-white w-[20%]  md:text-9xl text-3xl  rounded-full ">
                {/* Your icon */}
                <img
                  src="./images/bar-graph.png"
                  alt="growths"
                  className="text-white h-8 md:h-12 "
                />{" "}
              </div>
              <div className="lg:w-[50%] w-[70%]">
                <p className="md:text-sm font-poppinsRegular md:h-8 text-sm">
                  With KuberPe, you get:
                </p>

                <p className="md:text-lg lg:text-xl text-sm md:mt-[0.5rem] lg:mt-[0rem] font-bold font-poppinsRegular">
                  {" "}
                  high returns with consistency
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Graph  */}
      <div>
        <Graph />
      </div>

      {/* 6th section  Why choose kuberpe */}

      <div>
        <Sliderhome />
      </div>

      {/* our trusted Partners */}

      {/* *********************************** */}

      <div id="ourinvestors">
        <div className="our bg-custom-color1 w-[100%] h-[450px]">
          <div className=" md:w-[85%]  w-[100%]  m-auto text-white   md:h-60 h-48 flex flex-col gap-3 items-center justify-center">
            <h1 className=" font-poppinsBold md:text-5xl text-3xl">
              Our tursted Partners
            </h1>
            <div className=" w-[80%]">
              <h2 className="text-sm md:text-2xl text-center  text-gray-400">
                Get safe and secure services with the best privacy and gold
                service providers
              </h2>
            </div>
          </div>

          <div className=" m-auto  w-[100%]   md:w-[85%]">
            <Marquee pauseOnHover speed={100}>
              <div className="w-[95%] flex gap-16 justify-center items-center">
                <div className=" md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/startupindiaKuberPe.png"
                    className="  bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/decentroKuberPe.png"
                    className=" bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/lendboxKuberPe.png"
                    className="  bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24 rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/phonepeKuberPe.png"
                    className=" bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>

                <div className="  md:h-24  h-24  rounded-lg border border-[#866A04] flex justify-center items-center ">
                  <img
                    src="./images/augmontKuberPe.png"
                    className="  bg-white h-[100%] w-[100%] rounded-lg  "
                    alt=""
                  />
                </div>
              </div>
            </Marquee>
          </div>
        </div>
      </div>
      {/* FAQ  Section*/}
        <Faq />
      {/* Carosel section */}
        {/* <Carosel /> */}
    </div>
  );
};

export default Home;
